/**
 * Created with JetBrains PhpStorm.
 * User: workzentre
 * Date: 1/8/14
 * Time: 12:33 PM
 * To change this template use File | Settings | File Templates.
 */

var map;
var directionsDisplay;
var directionsService;
var stepDisplay;
var markerArray = [];
var intermedios = [];

function initialize() {
  // Instantiate a directions service.
  directionsService = new google.maps.DirectionsService();

  // Create a map and center it on tequisq.
  var tequisq = new google.maps.LatLng(20.5362418,-99.8936143);
  var mapOptions = {
    zoom: 17,
    center: tequisq,
    scrollwheel: false,
  }
  map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

  // Create a renderer for directions and bind it to the map.
  var rendererOptions = {
    map: map
  }
  directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions)

  // Instantiate an info window to hold step text.
  stepDisplay = new google.maps.InfoWindow();
  calcRoute();
}

function calcRoute(intermedio,intermedioB) {

  // First, remove any existing markers from the map.
  for (var i = 0; i < markerArray.length; i++) {
    markerArray[i].setMap(null);
  }

  // Now, clear the array itself.
  markerArray = [];
  intermedios = [];
  // Retrieve the start and end locations and create
  // a DirectionsRequest using WALKING directions.

  var otherStart = document.getElementById('end').value;
  var end = new google.maps.LatLng(20.5362418,-99.8936143);

      intermedios.push({
          location:intermedio,
          stopover:true
      });

      if(intermedioB){
        intermedios.push({
            location:intermedioB,
            stopover:true
        });
      }
      //console.log(intermedios);

      if(intermedio){
              var request = {
                origin: otherStart,
            // here I change the origin of the map
            destination: end,
            waypoints: intermedios,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidTolls: false,
            avoidHighways: false
          }
      } else {

              var request = {
                origin: otherStart,
            // here I change the origin of the map
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidTolls: false,
            avoidHighways: false
          }
   }

  // Route the directions and pass the response to a
  // function to create markers for each step.
  directionsService.route(request, function(response, status) {
    if (status == google.maps.DirectionsStatus.OK) {
      var warnings = document.getElementById('warnings_panel');
      warnings.innerHTML = '<b>' + response.routes[0].warnings + '</b>';
      directionsDisplay.setDirections(response);
      showSteps(response);
    }
  });
}

function showSteps(directionResult) {
  // For each step, place a marker, and add the text to the marker's
  // info window. Also attach the marker to an array so we
  // can keep track of it and remove it when calculating new
  // routes.
  var myRoute = directionResult.routes[0].legs[0];

  for (var i = 0; i < myRoute.steps.length; i++) {
    var marker = new google.maps.Marker({
      position: myRoute.steps[i].start_location,
      map: map
    });
    attachInstructionText(marker, myRoute.steps[i].instructions);
    markerArray[i] = marker;
  }
}

function attachInstructionText(marker, text) {
  google.maps.event.addListener(marker, 'click', function() {
    // Open an info window when the marker is clicked on,
    // containing the text of the step.
    stepDisplay.setContent(text);
    stepDisplay.open(map, marker);
  });
}

google.maps.event.addDomListener(window, 'load', initialize);


$(function(){

  $('.mapaGoogle').on('change', function(){
    var elIntermedio = $("#end option:selected").attr('data-intermedia');
    var elIntermedioB = $("#end option:selected").attr('data-intermediaB');
    calcRoute(elIntermedio,elIntermedioB);
  });

});

  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-64458319-1', 'auto');
  ga('send', 'pageview');
